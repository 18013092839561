<template>
  <div id="app" class="relative w-[100vw] h-[100vh]" :style="{ 'background-color': bgColor.hex }">
    
    <!-- backgroung options -->
    <img v-show="bgType == 'image'" :src="bgImage" class="fixed w-full h-full object-cover" alt="image_bg">
    
    <!-- content -->
    <div v-if="!lock" class="relative container h-full w-full pt-[30px] flex items-center justify-center">
      <div class="p-4 rounded-full -mt-[40%] space-y-2">

        <div  v-if="style_selected == 1" class="relative aspect-square flex items-center p-8  bg-clip-text text-transparent" 
        :style="{ 'background-image': 'linear-gradient(to top right,'+txtColor.hex+', '+txtColorTop.hex+')' }">
          <div class="w-full flex items-center justify-center font-['bebas_neue'] font-bold"
          :style="{ 'font-size': txtSize+'px' }">
            <div v-if="show_day" >{{ days }}:</div>
            <div v-if="show_hour" >{{ hours }}:</div>
            <div v-if="show_min" >{{ minutes }}:</div>
            <div v-if="show_sec" >{{ seconds }}</div>
          </div>
        </div>

        <div v-if="style_selected == 2" class="relative flex items-center p-8  bg-clip-text text-transparent" 
        :style="{ 'background-image': 'linear-gradient(to top right,'+txtColor.hex+', '+txtColorTop.hex+')' }">
          <div class="absolute inset-0 border-transparent border-[10px] border-solid rounded-full" 
          :style="{ 'border-image': 'linear-gradient(to top right,'+txtColor.hex+', '+txtColorTop.hex+') 1' }"></div>
          <div class="w-full flex items-center justify-center font-['bebas_neue'] font-bold"
          :style="{ 'font-size': txtSize+'px' }">
            <div v-if="show_day" >{{ days }}:</div>
            <div v-if="show_hour" >{{ hours }}:</div>
            <div v-if="show_min" >{{ minutes }}:</div>
            <div v-if="show_sec" >{{ seconds }}</div>
          </div>
        </div>

      </div>
    </div>

    <!-- footer -->
    <div v-if="!lock" class="absolute bottom-0 inset-x-0">

      <div class="relative container px-4 py-2 bg-gray-200 text-black select-none">

        <!-- toggle hide/show settings -->
        <div @click="hide_settings = !hide_settings" class="absolute bottom-[100%] right-0 bg-black px-4 py-1 text-sm rounded text-white cursor-pointer">
          {{ hide_settings ? 'Show' : 'Hide' }} Settings
        </div>

        <div v-show="!hide_settings" class="w-full grid grid-cols-6 gap-4">
          
          <div class="relative">
            <div class="text-xs flex space-x-2 cursor-pointer">Background Color</div>
            <div @click="show_bgcolorpicker = ! show_bgcolorpicker" class="flex items-center gap-4 cursor-pointer hover:bg-gray-300 px-2 rounded-lg">
              <div class="w-4 h-4 rounded-full" :style="{ 'background-color': bgColor.hex }"></div> {{ bgColor.hex }}
            </div>
            <div v-show="show_bgcolorpicker" class="absolute z-50 -top-[150px] -left-[230px] text-right">
              <span @click="show_bgcolorpicker=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
              <colour-picker v-model="bgColor" :value="bgColor.hex" picker="chrome"></colour-picker>
            </div>
          </div>

          <div class="overflow-hidden">
            <div class="text-xs uppercase">Background Image</div>
            <div>
              <input type="file" accept="image/*" ref="imageFile" @change="uploadImage" class="text-xs text-black">
            </div>
          </div>

          <div class="overflow-hidden">
            <div class="text-xs uppercase mb-[1px]">Timer Styles</div>
            <select name="bg" v-model="style_selected" class="w-full rounded text-sm border-gray-500 border text-black px-2">
                <option v-for="style in styles" :key="style.name" :value="style.v">{{ style.name }}</option>
            </select>
          </div>

          <div class="overflow-hidden">
            <div class="text-xs uppercase">Label Font Size</div>
            <div class="w-full relative flex items-center">
              <input type="number" min="10" v-model="txtSize" class="text-sm px-2 rounded-lg w-full mr-4">
              <i @click="txtSize--" class="i-down-big"></i>
              <i @click="txtSize++" class="i-up-big"></i>
            </div>
          </div>

          <div class="text-xs space-y-2">
            <div class="w-full relative">
              <div @click="tc_show = !tc_show" class="flex items-center cursor-pointer hover:bg-gray-100 px-2 rounded">
                <div class="w-4 h-4 rounded-full mr-4" :style="{ 'background-color': txtColor.hex }"></div> From {{  txtColor.hex }} 
              </div>
              <div v-show="tc_show" class="absolute z-50 -top-[150px] -right-[200px] text-right">
                <span @click="tc_show=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
                <colour-picker v-model="txtColor" :value="txtColor.hex" picker="chrome"></colour-picker>
              </div>
            </div>
            <div class="w-full relative">
              <div @click="tc_show_top = !tc_show_top" class="flex items-center cursor-pointer hover:bg-gray-100 px-2 rounded">
                <div class="w-4 h-4 rounded-full mr-4" :style="{ 'background-color': txtColorTop.hex }"></div> To {{  txtColorTop.hex }} 
              </div>
              <div v-show="tc_show_top" class="absolute z-50 -top-[150px] -right-[200px] text-right">
                <span @click="tc_show_top=false" class="px-2 py-1 rounded-lg bg-black cursor-pointer text-white mb-1">Close</span>
                <colour-picker v-model="txtColorTop" :value="txtColorTop.hex" picker="chrome"></colour-picker>
              </div>
            </div>
          </div>

          <div class="overflow-hidden">
            <div></div>
            <div @click="resetSetting()" class="mt-4 text-xs uppercase bg-red-500 hover:bg-red-600 rounded-lg text-white text-center">Reset Setting</div>
          </div>

        </div>

      </div>
      <div class="container p-4 bg-gray-300 text-black">

        <!-- stats -->
        <div class="w-full grid grid-cols-4 gap-4 select-none">
          
          <div class="space-y-1">
            <div class="text-sm flex items-center justify-between">
              <span>Days:</span> 
              <div class="space-x-2">
                <span @click="show_day = true" :class="show_day ? 'bg-green-500 text-white' : ''" class="border rounded px-2 cursor-pointer">Show</span>
                <span @click="show_day = false" :class="!show_day ? 'bg-gray-500 text-white' : ''" class="border rounded px-2 cursor-pointer">Hide</span>
              </div>
            </div>
            <input type="number" min="0" v-model="input_day" @change="setTimer()" :disabled="btnSummit" placeholder="0"  class="w-full text-sm p-2 rounded-lg">
          </div>

          <div class="space-y-1">
            <div class="text-sm flex items-center justify-between">
              <span>Hours:</span> 
              <div class="space-x-2">
                <span @click="show_hour = true" :class="show_hour ? 'bg-green-500 text-white' : ''" class="border rounded px-2 cursor-pointer">Show</span>
                <span @click="show_hour = false" :class="!show_hour ? 'bg-gray-500 text-white' : ''" class="border rounded px-2 cursor-pointer">Hide</span>
              </div>
            </div>
            <input type="number" min="0" v-model="input_hour" @change="setTimer()" :disabled="btnSummit" placeholder="0" class="w-full text-sm p-2 rounded-lg">
          </div>

          <div class="space-y-1">
            <div class="text-sm flex items-center justify-between">
              <span>Minutes:</span> 
              <div class="space-x-2">
                <span @click="show_min = true" :class="show_min ? 'bg-green-500 text-white' : ''" class="border rounded px-2 cursor-pointer">Show</span>
                <span @click="show_min = false" :class="!show_min ? 'bg-gray-500 text-white' : ''" class="border rounded px-2 cursor-pointer">Hide</span>
              </div>
            </div>
            <input type="number" v-model="input_min" @change="setTimer()" min="0" placeholder="0" :disabled="btnSummit" class="w-full text-sm p-2 rounded-lg">
          </div>

          <div class="space-y-1">
            <div class="text-sm flex items-center justify-between">
              <span>Seconds:</span> 
              <div class="space-x-2">
                <span @click="show_sec = true" :class="show_sec ? 'bg-green-500 text-white' : ''" class="border rounded px-2 cursor-pointer">Show</span>
                <span @click="show_sec = false" :class="!show_sec ? 'bg-gray-500 text-white' : ''" class="border rounded px-2 cursor-pointer">Hide</span>
              </div>
            </div>
            <input type="number" v-model="input_sec" @change="setTimer()" min="0" :disabled="btnSummit" placeholder="0" class="w-full text-sm p-2 rounded-lg">
          </div>

        </div>
        <div class="grid grid-cols-1 gap-2 mt-4">
          <div class="w-full">
            <div v-show="btnSummit" @click="resetValue()" class="py-1 text-center rounded-lg cursor-pointer bg-red-500 text-white hover:bg-red-600">Stop</div>
          </div>
          <div @click="submitStats()" v-show="!btnSummit"
          class="bg-blue-500 p-1 rounded-lg text-center text-white hover:bg-blue-600 cursor-pointer">Start Countdown</div>
        </div>
      </div>
    </div>

    <div @click="tc_show = false" v-show="tc_show" class="absolute inset-0 bg-tranparent"></div>
    <div @click="tc_show_top = false" v-show="tc_show_top" class="absolute inset-0 bg-tranparent"></div>
    <div @click="show_bgcolorpicker = false" v-show="show_bgcolorpicker" class="absolute inset-0 bg-tranparent"></div>

    <div v-show="lock" class="absolute inset-0 bg-gray-900 flex items-center justify-center">
        <div class="bg-gray-800 p-6 rounded-lg text-white">
          <input type="text" v-model="passcode" @keydown.enter="verify()" placeholder="Please enter the passcode" class="rounded p-2 text-black">
          <div v-if="incorrect" class="text-red-500 text-sm">Passcode is incorrect</div> 
          <div @click="verify()" class="text-center p-2 my-2 bg-gray-900">Enter</div>
        </div>
    </div>
    
  </div>
</template>

<script>

import { Chrome } from 'vue-color'
export default {
  components: {
    'colour-picker': Chrome,
  },
  data() {
    return {
      lock: true,
      passcode: '', 
      incorrect: false,
      hide_settings: false,
      message: 'Hello world',
      bgColor: {
        hex: '#FFFFFF'
      },
      show_bgcolorpicker: false,
      bgImage: null,
      bgType: '',

      style_selected: '1',
      styles: [
        { name: 'Plain', v: '1' },
        { name: 'With Border', v: '2' },
      ],

      contentWidth: '500',
      btnSummit: false,

      txtColor: {
        hex: '#000000'
      },
      txtColorTop: {
        hex: '#6356FF'
      },
      tc_show_top: false,
      tc_show: false,
      txtSize: '100',
      
      input_day: null,
      input_hour: null,
      input_min: null,
      input_sec: null,
      timer: null,
      isRunning: null,

      day: null,
      hour: null,
      min: null,
      sec: null,

      show_day: true,
      show_hour: true,
      show_min: true,
      show_sec: true
    }
  },
  methods: {
    uploadImage(e) {
      this.resetBg('video')
      this.bgType = "image"

      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
          this.bgImage = e.target.result;
      };
      
    },
    uploadColor() {
      this.resetBg('iv')
      this.bgType = "color";
    },
    resetBg(type) {
      if (type == 'image') 
      {
        this.bgImage = null
        this.$refs.imageFile.value = null
      }

      if (type == 'iv') {
        this.bgImage = null
        this.$refs.imageFile.value = null
        this.bgVideo.src = ''
        this.$refs.videoFile.value = null
      }
      if (type == 'all') {
        this.contentWidth = 500
        this.bgColor.hex = '#FFFFFF' 
        this.bgImage = null
        this.$refs.imageFile.value = null
        this.bgVideo.src = ''
        this.$refs.videoFile.value = null
      }
    },
    changeBgColor() {
      this.bgImage = null
    },
    setTimer()
    {
      this.timer = (parseInt(this.input_day ?? 0) * 24 * 60 * 60 * 1000) + 
        (parseInt(this.input_hour ?? 0) * 60 * 60 * 1000) + 
        (parseInt(this.input_min ?? 0) * 60 * 1000) +
        (parseInt(this.input_sec ?? 0) * 1000);

      this.sec = parseInt(this.timer / 1000);
      this.min = parseInt(this.sec / 60);
      this.hour = parseInt(this.min / 60);
      this.day = parseInt(this.hour / 24);
    },
    submitStats() {

      this.isRunning = setInterval(() => {
        if (this.timer > 0) {
          this.timer = this.timer - 1000;
          this.sec = parseInt(this.timer / 1000);
          this.min = parseInt(this.sec / 60);
          this.hour = parseInt(this.min / 60);
          this.day = parseInt(this.hour / 24);
        } else {
          this.resetValue();
        }
      }, 1000);

      this.btnSummit = true;
    },
    resetValue() {
      clearInterval(this.isRunning);
      this.isRunning = null;
      this.timer = 0;
      this.day = 0;
      this.hour = 0;
      this.min = 0;
      this.sec = 0;

      this.input_sec = null;
      this.input_min = null;
      this.input_hour = null;
      this.input_day = null;
      this.btnSummit = false;

    },
    resetSetting()
    {
      this.resetValue();
      this.resetBg('all');
    },
    verify()
    {
      if (this.passcode === 'happygaming123') {
        this.lock = false;
      } else {
        this.incorrect = true;
      }
    }
  },
  computed: {
    days() {
      let days = this.day ?? this.input_day ?? 0;
      return days > 9 ? days : '0' + days;
    },
    hours() {
      let hours = (this.hour % 24) ?? this.input_hour ?? 0;
      return hours > 9 ? hours : '0' + hours;
    },
    minutes() {
      let minutes = (this.min % 60) ?? this.input_min ?? 0;
      return minutes > 9 ? minutes : '0' + minutes;
    },
    seconds() {
      let seconds = (this.sec % 60) ?? this.input_sec ?? 0;
      return seconds > 9 ? seconds : '0' + seconds;
    }
  },
}
</script>
